import * as React from "react";

// import pages
import { HomePage } from "./pages";
import { Navigate, useLocation } from "react-router-dom";
import { FixedMenu } from "./components";

const RootLayout = () => {
	const location = useLocation();

	return location.pathname !== "https://fayezbinsaleh.me/success-message" ? (
		<div className='app-wrapper'>
			<HomePage />

			{/* Fixed Menu */}
			<FixedMenu />
		</div>
	) : (
		<Navigate state={{ from: location }} replace to='/success-message' />
	);
};

export default RootLayout;

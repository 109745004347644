import React from "react";
import { useNavigate } from "react-router-dom";

import { Helmet, HelmetProvider } from "react-helmet-async";

// import Components
import { Navbar, SuccessMessageModal } from "../../components";

// import css styles
import "../../index.css";
import styles from "./HomePage.module.css";

// images ans icons
import { Logo } from "../../assets/icons";
import { FayezImage, Hero_bg_img } from "../../assets/images";

const HomePage = () => {
	const navigate = useNavigate();

	//---------------------------------------------------------------------------------------------

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title> فايز بن صالح | الرئيسية </title>
			</Helmet>

			<div
				className={`${styles.hero_style}`}
				style={{ backgroundImage: `url(${Hero_bg_img}) ` }}>
				<div className={`${styles.overlay}`}></div>

				{/*Navbar */}
				<Navbar
					pageTitle={
						<img
							onClick={() => navigate("/")}
							className='h-[53px] max-w-[98px] w-auto cursor-pointer'
							src={Logo}
							alt='website logo'
						/>
					}
				/>

				<div className={`openPages z-10 relative pt-5`}>
					<div className='container'>
						<div className='flex flex-col justify-center items-center text-center mb-4'>
							<h1
								className={`text-white font-Tajawal text-[54px] font-bold ${styles.hero_title}`}>
								فايز بن صالح
							</h1>
							<p
								className={`text-white font-Tajawal text-[22px] font-medium ${styles.hero_title}`}>
								مرشدك في رحلتك التجارية إلى الصين
							</p>
						</div>
						<div className='hero__image'>
							<img src={FayezImage} alt='' loading='lazy' />
						</div>

						<div className={`flex flex-col justify-center`}>
							<button
								onClick={() => navigate("about-fayez-bin-saleh")}
								className='btn_style bg-btn-bg-bck mb-3'>
								نبذة عن فايز بن صالح
							</button>
							<button
								onClick={() => navigate("business-trip-program-to-china")}
								className='btn_style btn_outline bg-transparent'>
								برنامج الرحلة التجارية إلى الصين
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* Success Message Modal this will open after user click on register button in register form page*/}
			<SuccessMessageModal />
		</HelmetProvider>
	);
};

export default HomePage;

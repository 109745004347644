import * as React from "react";
import * as ReactDOM from "react-dom/client";

// Import these methods to create app routes

import { createBrowserRouter, RouterProvider } from "react-router-dom";

// import css styles file
import "./index.css";

// import all pages
import {
	AboutFayez,
	Blog,
	BusinessTripProgramToChina,
	CommonQuestions,
	CustomerService,
	NotFoundPage,
	ProgramAdvantages,
	RegistrationFormForTrainingProg,
	RootLayout,
	TraineesOpinions,
} from "./pages";
import ContextProvider from "./context/ContextProvider";
import SubscribeTrainingProgram from "./pages/subscribeTrainingProgram/SubscribeTrainingProgram";
import { ArticleContent } from "./components";

// All App Routes
const router = createBrowserRouter([
	/** Home page */

	{
		path: "/",
		element: <RootLayout />,
		errorElement: <NotFoundPage />,
	},

	/** success message page */
	{
		path: "success-message",
		element: <RootLayout />,
		index: true,
	},

	/** About Fayez bin Saleh page */
	{
		path: "about-fayez-bin-saleh",
		element: <AboutFayez />,
	},

	/** Business Trip Program To China page */
	{
		path: "business-trip-program-to-china",
		element: <BusinessTripProgramToChina />,
	},

	/** program advantages page */
	{
		path: "program-advantages",
		element: <ProgramAdvantages />,
	},

	/** Trainees Opinions  page */
	{
		path: "trainees-opinions",
		element: <TraineesOpinions />,
	},

	/** subscribe on training program */
	{
		path: "subscribe-training-program",
		element: <SubscribeTrainingProgram />,
	},

	/** Registration form for the training program (business trip to China) */
	{
		path: "registration-form-for-the-training-program",
		element: <RegistrationFormForTrainingProg />,
	},

	/** common-questions page */
	{
		path: "common-questions",
		element: <CommonQuestions />,
	},

	/** Customer Service page */
	{
		path: "customer-service",
		element: <CustomerService />,
	},
	/** Blog page */
	{
		path: "blog",
		element: <Blog />,
	},
	// article content pages
	{
		path: "blog/:title",
		element: <ArticleContent />,
	},
]);

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<ContextProvider>
			<RouterProvider router={router} />
		</ContextProvider>
	</React.StrictMode>
);

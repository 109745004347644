import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

// import components
import {
	FixedMenu,
	MadaPayment,
	Navbar,
	PagesLinks,
	WhatsappGroupBanner,
} from "../../components";

// IMPORT STYLES
import "../../index.css";
import styles from "./BusinessTripProgramToChina.module.css";

// import all images and icons
import { ArrowDown, ArrowRightOutline, Logo, Star } from "../../assets/icons";
/*-------------------------*/

import { BusinessTripProgramToChinaImg } from "../../assets/images";

const BusinessTripProgramToChina = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title> برنامج الرحلة التجارية إلى الصين </title>
			</Helmet>

			<div className={`openPages bg-white h-full overflow-y-auto pb-6`}>
				{/*Navbar */}
				<Navbar
					background={"bg-red"}
					pageTitle={
						<img
							onClick={() => navigate("/")}
							className='h-[53px] max-w-[98px] w-auto cursor-pointer'
							src={Logo}
							alt='website logo'
						/>
					}
				/>
				{/*------------------------------------------*/}
				<div className='container'>
					{/* back to home icon */}
					<div className='flex justify-between'>
						<ArrowRightOutline
							className=' cursor-pointer'
							onClick={() => navigate("/about-fayez-bin-saleh")}
						/>
						<Star />
					</div>

					{/*title*/}
					<div className='mx-auto mb-5 '>
						<h2
							className={`page_title text-center  font-Tajawal font-bold text-2xl text-black pb-2`}>
							برنامج الرحلة التجارية إلى الصين
						</h2>

						<p className='sub_title  text-center  font-Tajawal font-medium text-xl text-black px-12'>
							البرنامج الذي يصنع علامتك التجارية وانطلاقة مشروعك التجاري
						</p>
					</div>

					{/* page_head */}
					<div className='mb-5'>
						<div
							className={`${styles.page_head} flex justify-center items-center mb-4 `}>
							<div className={`${styles.pages_cover}`}>
								<img
									className={`${styles.Program_img_style}`}
									src={BusinessTripProgramToChinaImg}
									loading='lazy'
									alt=''
								/>
							</div>
						</div>
					</div>

					{/*about  content */}
					<div className='w-100 mb-5'>
						<p className=' text-center font-Tajawal font-medium text-xl text-black'>
							برنامج تدريبي{" "}
							<span className=' bg-heighLight'>وجولات حقيقية ومباشرة </span> من
							داخل الصين لتبدأ رحلتك في التجارة حيث نصطحبك برفقة خبراء في مجال
							الأعمال لتصل إلى أقل الأسعار من محلات ومصانع الجملة
						</p>
					</div>
				</div>
				{/* Pages Routs */}
				<div className='mb-5'>
					<PagesLinks
						title={"	مميزات البرنامج"}
						arrow_icon={
							<ArrowDown className=' cursor-pointer animate-bounce' />
						}
						route={"/program-advantages"}
					/>
				</div>

				{/* whatsapp group banner*/}
				{/*<WhatsappGroupBanner />*/}

				{/* Fixed Menu */}
				<FixedMenu posBottomValue='bottom-[140px]' />
			</div>
		</HelmetProvider>
	);
};

export default BusinessTripProgramToChina;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// import css styles
import "../../index.css";
import styles from "./SubscribeTrainingProgramPackage.module.css";

// icons amd images
import { Star } from "../../assets/icons";
import Context from "../../context/Context";

const packagesData = [
	{
		id: 1,
		title: "الباقة الإقتصادية",
		price: "12,000",
		details: [
			{ id: 1, title: "غرفة مشتركة (شخصين)" },
			{ id: 2, title: "ضيافة عامة داخل القاعة" },
			{ id: 3, title: "اشتراك في متجر" },
			{ id: 4, title: "تصميم شعار" },
		],
	},
	{
		id: 2,
		title: "باقة الأعمال",
		price: "14,000",
		details: [
			{ id: 1, title: "غرفة خاصة" },
			{ id: 2, title: "مساعد شخصي" },
			{ id: 3, title: "مقاعد خاصة في القاعة بالمقدمة (كنب)" },
			{ id: 4, title: "استشارة طوال وقت البرنامج" },
			{ id: 5, title: "ضيافة خاصة في القاعة" },
			{ id: 6, title: "شريحة اتصال انترنت" },
			{ id: 7, title: "اشتراك بمتجر الكتروني وتصميم شعار" },
			{ id: 8, title: "4تصاميم بوستات إعلانية" },
			{ id: 9, title: "فيديو اعلاني للمتجر" },
			{ id: 12, title: "اعلان مجاني في صفحات التواصل" },
			{ id: 13, title: "بوكس تصوير المنتجات" },
		],
	},
];
const SubscribeTrainingProgramPackage = () => {
	const navigate = useNavigate();

	// To set the package and price to register
	const PackagesContent = useContext(Context);
	const { setPackageType, setPackagePrice } = PackagesContent;

	const closeHintRegisterContext = useContext(Context);
	const { setCloseHintRegister } = closeHintRegisterContext;

	// To send the the type of package and price to register form
	const handleNavigateToRegisterForm = (
		packageType,
		packagePrice,
		packageId
	) => {
		navigate("/registration-form-for-the-training-program");
		setPackageType(packageType);
		setPackagePrice(packagePrice);

		localStorage.setItem("packageId", packageId);
		setCloseHintRegister(true);
	};

	// Packages map array
	const packages = packagesData.map((pack) => (
		<div
			className={`mb-8 rounded-lg  ${styles.packages_container}`}
			key={pack?.id}>
			<div
				className={`${styles.title} ${
					pack.title === "الباقة الإقتصادية" ? "bg-[#a1182f]" : "bg-[#CDC10D]"
				}   text-center text-white text-lg font-Tajawal font-medium h-11 flex items-center justify-center`}>
				{pack.title}
			</div>
			<div className={`${styles.packages_content} p-4`}>
				<ul className={`${styles.content_list} pb-5`}>
					{pack?.details?.map((item) => (
						<li
							key={item?.id}
							className={`${styles.article_list_items} h-6 flex justify-start items-center gap-2 text-[16px] font-Tajawal font-normal text-black mb-3`}>
							<div className='w-[23px] h-[20px] max-w-full'>
								<Star className='w-full' />
							</div>
							{item?.title}
						</li>
					))}
				</ul>

				<button
					onClick={() => {
						handleNavigateToRegisterForm(pack.title, pack.price, pack?.id);
					}}
					className={` bg-black flex items-center  justify-center rounded h-12 text-white w-full`}>
					<div className=' flex items-center justify-center h-full '>
						<span className=' font-Tajawal font-medium text-lg me-4  '>
							{pack.title}
						</span>
						<span className=' font-Tajawal font-bold text-xl me-1  '>
							{pack.price}
						</span>
						<span className='font-Tajawal font-medium text-lg  '>ر.س</span>
					</div>
				</button>
			</div>
		</div>
	));

	return <>{packages}</>;
};

export default SubscribeTrainingProgramPackage;

import React, { Fragment, useContext } from "react";
import ReactDom from "react-dom";

// import css styles files
import "../../index.css";
import styles from "./MoreInfoModal.module.css";

// Import context
import Context from "../../context/Context";

// Icons
import { CloseIcon } from "../../assets/icons";

const MoreArticleInfo = () => {
	const ArticleInfoModal = useContext(Context);
	const { openMoreInfoModal, setOpenMoreInfoModal, title, description } =
		ArticleInfoModal;

	return (
		openMoreInfoModal && (
			<div>
				{/* Backdrop */}
				<div className={`${styles.backdrop} z-20`} />
				<div
					className={` ${styles.z_index_100} openModal fixed left-0 top-[80px] w-full min-w-[380px] max-w-[428px] h-full `}>
					<div className='flex items-center justify-center p-4 text-center'>
						<div className='transform w-full h-full bg-white align-middle shadow-xl transition-all'>
							{/* Head of modal */}
							<div className='bg-yellow w-full h-11 flex items-center '>
								{/* Modal Title */}
								<h3 className='text-lg flex-1 h-full font-medium leading-6 text-black text-center flex justify-center items-center'>
									{title}
								</h3>

								{/* Close Icon */}
								<div className='ml-2 cursor-pointer'>
									<CloseIcon onClick={() => setOpenMoreInfoModal(false)} />
								</div>
							</div>

							{/* modal content */}
							<div className='mt-2 p-6  max-h-[600px]   overflow-y-auto '>
								<p className='text-[16px] font-normal text-right text-black mb-2'>
									{description}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

const MoreInfoModal = () => {
	return (
		<Fragment>
			{ReactDom.createPortal(
				<MoreArticleInfo />,
				document.getElementById("more_article_info")
			)}
		</Fragment>
	);
};

export default MoreInfoModal;

import React, { useState } from "react";

import Context from "./Context";

export const ArticleMoreInfo = React.createContext();
const ContextProvider = (props) => {
	/**-------------------------------------------------------------
	 *  i`m use this modal in
	 1- Training program registration policy,
	 2- and Precautions while traveling to China,
	 3- and more Article info modal 
	 ----------------------------------------------------------------*/
	const [openMoreInfoModal, setOpenMoreInfoModal] = useState(false);
	const [description, setDescription] = useState(null);
	const [title, setTitle] = useState(null);
	const [btnContent, setBtnContent] = useState(null);
	const [acceptCondition, setAcceptCondition] = useState(null);
	const [acceptPolicy, setAcceptPolicy] = useState(null);

	// -------------------------------------------------------------------

	const [packageType, setPackageType] = useState(null);
	const [packagePrice, setPackagePrice] = useState(null);
	const [packageId, setPackageId] = useState(null);
	const [closeHintRegister, setCloseHintRegister] = useState(false);
	// -------------------------------------------------------------------

	// Blog Article content
	const [blogArticleContent, setBlogArticleContent] = useState(null);
	// -------------------------------------------------------------------

	// handle open and close success message modal after click register button
	const [openSuccessMessage, setOpenSuccessMessage] = useState(false);

	const context = {
		setOpenMoreInfoModal,
		openMoreInfoModal,
		description,
		setDescription,
		title,
		setTitle,
		btnContent,
		setBtnContent,
		acceptCondition,
		setAcceptCondition,
		acceptPolicy,
		setAcceptPolicy,
		// -------------------------------
		packageType,
		setPackageType,
		packagePrice,
		setPackagePrice,
		packageId,
		setPackageId,
		closeHintRegister,
		setCloseHintRegister,
		blogArticleContent,
		setBlogArticleContent,
		openSuccessMessage,
		setOpenSuccessMessage,
	};

	return <Context.Provider value={context}>{props.children}</Context.Provider>;
};

export default ContextProvider;

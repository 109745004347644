import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

// components
import { FixedMenu, Navbar } from "../../components";

// import styles files
import "../../index.css";
import styles from "./AboutFayez.module.css";

// import icons
import { ArrowRight, Logo } from "../../assets/icons";
import { FayezAbout } from "../../assets/images";

const AboutFayez = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title> نبذة عن فايز بن صالح </title>
				<meta
					name='description'
					content='خبير بالتجارة الالكترونية وخبير تسويقي.'
				/>
			</Helmet>

			<div className={`openPages bg-about-bg pb-10`}>
				{/*Navbar */}
				<Navbar
					background={"bg-red"}
					pageTitle={
						<img
							onClick={() => navigate("/")}
							className='h-[53px] max-w-[98px] w-auto cursor-pointer'
							src={Logo}
							alt='website logo'
						/>
					}
				/>
				{/*------------------------------------------*/}

				<div className='container'>
					{/*back to home icon*/}
					<div className={`${styles.arrow_right} `}>
						<ArrowRight
							className=' cursor-pointer'
							onClick={() => navigate("/")}
						/>
					</div>
					{/* page_head */}
					<div className='mb-5'>
						<div
							className={`${styles.page_head} flex justify-center items-center mb-4 `}>
							<div className={`${styles.pages_cover}`}>
								<img src={FayezAbout} loading='lazy' alt='' />
							</div>
						</div>

						{/*title*/}
						<div className='w-44 mx-auto '>
							<h2
								className={`border_bottom text-center  font-Tajawal font-medium text-xl text-black pb-2`}>
								نبذة عن فايز بن صالح
							</h2>
						</div>
					</div>

					{/* about  content */}

					<div className={`w-full mb-5 max-h-[360px] overflow-y-auto `}>
						<p className='content text-right  text-xl font-normal text-black mb-2'>
							نشأ فايز في أسرة متوسطة الدخل ولكن بفترة من فترات حياته مرت الأسرة
							بظروف مادية صعبة وصلت بهم إلى حد الفقر.
						</p>
						<p className='content text-right  text-xl font-normal text-black mb-2'>
							بدأ فايز بالعمل في سن صغيرة من أجل مساعدة أسرته وعمل في مجالات
							كثيرة خلال هذه الفترة مما أعطاه ميزة بأن يكسب الكثير من الخبرات
							والمهارات بمجالات مختلفة.
						</p>
						<p className='content text-right  text-xl font-normal text-black mb-2'>
							بعد كثير من الجهد و العمل استطاع فايز تحسين المستوى المادي و تأمين
							أسرته مادياً مما أعطاه فرصة ليبدأ بتأسيس مشاريعه الخاصة..
						</p>
						<p className='content text-right  text-xl font-normal text-black mb-2'>
							سنة 2016 بدأ في شق طريقه بشكل فعلي و قرر أن يكون أول مشروع له نابع
							من شغفه (العمل التجاري).
						</p>
						<p className='content text-right  text-xl font-normal text-black mb-2'>
							أسس فايز أول متجرالكتروني{" "}
							<span className='font-bold'>(اطلبها)</span> واستطاع النجاح في هذه
							المجال مما دفعه إلي إنشاء مؤسسة للخدمات البرمجية و التسويق بعد
							تحقيق نجاح في المشروع اتجه اهتمام فايز إلي الصين وأسس مكتبه الخاص
						</p>
						<p className='content text-right  text-xl font-normal text-black mb-2'>
							الآن وبعد الظروف التي عانى منها في بداياتة وما وصل إليه من نجاح
							مؤخرا وبعد ممارسة عدة أعمال بأكثر من مجال لفترة طويلة
						</p>
						<p className='content text-right  text-xl font-normal text-black mb-2'>
							والتمكُّن من النجاح والاستمرارية بها قرر فايز بأن يعزز كل الخبرات
							والمهارات التي اكتسبها ليفيد مجتمعه ويأخذ بأيدي الشباب
						</p>
						<p className='content text-right  text-xl font-normal text-black mb-2'>
							نحو النجاح المالي في إنشاء وإدارة المشاريع. بحيث يختصر عليهم سنين
							من البحث التجربة ويعطيهم فرصة للحصول علي عصارة خبراته في تقديم
							دورات وورش عمل. فبادر بعدد من البرامج التدريبية المجانيه{" "}
							<span className=' font-medium'>
								بدعم المؤسسة العامة للتدريب التقني والمهني
							</span>{" "}
							لـ عدد وصل لـ 20 الف مشترك. تحت عنوان{" "}
							<span className=' font-bold'>
								(مهارات التجارة والتسويق الالكتروني)
							</span>{" "}
							وبرنامج{" "}
							<span className=' font-bold'>(إداة المبيعات والتسويق)</span>
						</p>

						<p className='content text-right  text-xl font-normal text-black mb-2'>
							كان يستهدف الشباب ومحدودي الدخل و الأسر المنتجة وأسر السجناء
							والضمان الاجتماعي وذوي الاحتياجات الخاصة. ليمكنهم بكل مايمكن من
							المعرفه والادوات للبدأ بمشاريعهم بما هو متاح لديهم ويقدم لهم الدعم
							والاستشارات.
						</p>

						<p className='content text-right  text-xl font-normal text-black mb-2'>
							يبقي شغفه في مجاله وحبه للمساهمة في بناء مجتمع مستقل وتقديم كل ما
							يمكنه من معلومات لنفع الناس هو المحرك والمحفز الرئيسي له.
						</p>
					</div>

					{/* office location */}
					<div className='bg-yellow flex flex-col justify-start gap-6  max-w[430px] rounded-lg p-4 h-[173px] mb-16'>
						<h3 className='title text-center '>مكتبنا في الصين</h3>

						{/*address*/}
						<div className=' text-right'>
							<h4 className='text-[16px] font-normal text-[#011723]'>
								عنوان المكتب
							</h4>
							<p className='text-[16px] font-medium text-ellipsis text-[#011723]'>
								جوانزو، شارع رقم 362
							</p>
						</div>
					</div>

					{/* Business trip program to China button */}
					<button
						onClick={() => navigate("/business-trip-program-to-china")}
						className='btn_style bg-red sticky bottom-0 mb-5 '>
						برنامج الرحلة التجارية إلى الصين
					</button>

					{/* Fixed Menu */}
					<FixedMenu posBottomValue='bottom-[787px]' />
				</div>
			</div>
		</HelmetProvider>
	);
};

export default AboutFayez;

import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

// import css styles
import "../../index.css";

// import icons and images
import { ArrowRightOutline } from "../../assets/icons";
import { SubscribeTrainingProgramBgImg } from "../../assets/images";

// import components
import { Navbar, SubscribeTrainingProgramPackage } from "../../components";

const SubscribeTrainingProgram = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title> باقات الاشتراك في برنامج الرحلة التجارية إلى الصين</title>
			</Helmet>

			<div className={`openPages bg-white h-full overflow-y-auto pb-12`}>
				{/*Navbar */}
				<Navbar
					background={"bg-red"}
					NavbarBgImg={SubscribeTrainingProgramBgImg}
					pageTitle={
						<h3 className=' w-64  text-white text-xl font-Tajawal font-normal '>
							باقات الاشتراك في برنامج الرحلة التجارية إلى الصين
						</h3>
					}
				/>
				{/*------------------------------------------*/}

				<div className='container'>
					{/* back to home icon */}
					<div className='flex justify-start gap-6 mb-5'>
						<ArrowRightOutline
							className=' cursor-pointer'
							onClick={() => navigate("/trainees-opinions")}
						/>
					</div>

					{/* packages info */}
					<SubscribeTrainingProgramPackage />
				</div>
			</div>
		</HelmetProvider>
	);
};

export default SubscribeTrainingProgram;

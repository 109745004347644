import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const commonQuestionsData = [
	{
		id: 1,
		QuestionCategory: "الفندق",
		questionAnswersArray: [
			{
				id: 1,
				QuestionTitle: "ماهو تصنيف الفندق؟",
				questionAnswer:
					"فندق مصنف 5 نجوم ودرجة الامان به جداً عالية والغرف مفروشة بالكامل",
			},
			{
				id: 2,
				QuestionTitle: "هل الفندق قريب من منطقة المطاعم العربية؟",
				questionAnswer: "نعم ويوجد بالقرب منه المطاعم العربية والبقالات الحلال",
			},
			{
				id: 34,
				QuestionTitle: "ما المقصود بالغرف المشتركة؟",
				questionAnswer:
					"هي غرفة بمساحة 35 مترا تسع لشخصين بسريرين ولا تشمل الوجبات",
			},
			{
				id: 3,
				QuestionTitle: "مالمقصود بغرفة خاصة؟",
				questionAnswer:
					"هي غرفة بمساحة 35 متراً بها سرير واحد وتكون اكثر خصوصية وبدون الوجبات",
			},
			{
				id: 4,
				QuestionTitle: "بماذا تنصحني؟",
				questionAnswer:
					"انصحك باختيار الغرفه الخاصة كونها اكثر استقلالية وراحة لك",
			},
		],
	},
	{
		id: 2,
		QuestionCategory: "الاتصال والانترنت",
		questionAnswersArray: [
			{
				id: 1,
				QuestionTitle: "هل يمكنني استخدام رقمي السعودي في الصين؟",
				questionAnswer: "نعم بحال تم تفعيل خاصية التجوال قبل السفر",
			},
			{
				id: 2,
				QuestionTitle: "هل الانتر نت سريع هناك؟",
				questionAnswer: "سرعته مقبولة وتفي بالغرض",
			},
			{
				id: 304,
				QuestionTitle: "هل يمكنني استخراج شريحة صينية واستخدامها؟",
				questionAnswer: "نعم يمكنك ذلك بكل سهولة",
			},
			{
				id: 3,
				QuestionTitle: "بماذا تنصحني؟",
				questionAnswer:
					"انصح بتفعيل شريحة تجوال طوال فترة اقامتك بالصين لتتمكن من استخدام كافة صفحات التواصل الاجتماعي (الواتساب - الانستجرام - تيك توك -السناب ...... ) كون الصين تحضر منصات التواصل الاجتماعي بها",
			},
		],
	},
	{
		id: 3,
		QuestionCategory: "أوقات الصلاة",
		questionAnswersArray: [
			{
				id: 1,
				QuestionTitle: "كيف اعرف اوقات الصلاة هناك؟",
				questionAnswer:
					"دولة الصين غير مسلمة فينصح باستخدام برامج اوقات الصلاة",
			},
			{
				id: 2,
				QuestionTitle: "هل يسمح باداء الصلاة في الأماكن العامة؟",
				questionAnswer:
					"لاننصح بالصلاة في الاماكن العامة ولكن سيرتب بالبرنامج اداء الصلاة جماعة",
			},
			{
				id: 83,
				QuestionTitle: "هل يمكنني توزيع منشورات دينية؟",
				questionAnswer: "لا ننصح بذلك ابدا ممنوع",
			},
			{
				id: 3,
				QuestionTitle: "بماذا تنصحني؟",
				questionAnswer:
					"تحميل برامج اوقات الصلاة والقبله واخذ سجادة ضمن امتعتك",
			},
		],
	},
	{
		id: 4,
		QuestionCategory: "الأسئلة العامة",
		questionAnswersArray: [
			{
				id: 1,
				QuestionTitle: "هل الموظف الحكومي يستطيع السفر الى الصين؟",
				questionAnswer: "نعم",
			},
			{
				id: 2,
				QuestionTitle: "هل الدورة مناسبة للرجال والنساء ومسموح الأطفال؟",
				questionAnswer: "نعم مناسة للرجال وللنساء وممنوع اصطحاب الأطفال",
			},
			{
				id: 3762,
				QuestionTitle: "هل بالإمكان الحصول على إرشادات ونصائح قبل السفر؟",
				questionAnswer: "نعم",
			},
			{
				id: 3,
				QuestionTitle: "هل بالإمكان حمل الأدوية؟",
				questionAnswer: "نعم",
			},
			{
				id: 4,
				QuestionTitle: "هل النقاب مسموح في الصين؟",
				questionAnswer: "نعم",
			},
			{
				id: 5,
				QuestionTitle: "هل بالإمكان حجز تاريخ العودة بعد الوصول بعدة أيام؟",
				questionAnswer: "نعم",
			},
		],
	},
];

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	borderRadius: "4px",
	"&:not(:last-child)": {
		borderBottom: 0,
	},
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
	({ theme }) => ({
		backgroundColor: "#FFF",
		flexDirection: "row",
		"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
			transform: "rotate(-90deg)",
		},
		"& .MuiAccordionSummary-content": {
			marginLeft: theme.spacing(1),
		},
	})
);

const AccordionSummary2 = styled((props) => <MuiAccordionSummary {...props} />)(
	({ theme }) => ({
		backgroundColor: "#E62237",
		flexDirection: "row",

		"& .MuiAccordionSummary-content": {
			marginLeft: theme.spacing(1),
		},
	})
);
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	border: "none",
	padding: "0px",
}));

export default function CommonQuestionAccording() {
	const [expanded, setExpanded] = React.useState("");
	const [expandedSecondAcc, setExpandedSecondAcc] = React.useState("");

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	const handleChangeSecondAcc = (panel) => (event, newExpanded) => {
		setExpandedSecondAcc(newExpanded ? panel : false);
	};

	const commonQuestion = commonQuestionsData.map((according) => (
		<Accordion
			expanded={expanded === `panel${according?.id}`}
			onChange={handleChange(`panel${according?.id}`)}>
			<AccordionSummary
				aria-controls={`panel${according?.id}-content`}
				id={`panel${according?.id}-header`}
				expandIcon={
					<ArrowForwardIosSharpIcon
						sx={{
							fontSize: "1rem",
							color: "#000",
							transform: "rotate(180deg)",
						}}
					/>
				}>
				<Typography
					sx={{
						fontSize: "20px",
						fontWeight: "500",
						fontFamily: "Tajawal",
						color: "#000",
					}}>
					{according?.QuestionCategory}
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{/* Sub according */}
				{according.questionAnswersArray.map((questionAnswer) => (
					<Accordion
						expanded={expandedSecondAcc === `panel${questionAnswer?.id}`}
						onChange={handleChangeSecondAcc(`panel${questionAnswer?.id}`)}>
						{/*  */}
						<AccordionSummary2
							aria-controls={`panel${questionAnswer?.id}-content`}
							id={`panel${questionAnswer?.id}-header`}
							expandIcon={
								<ExpandMoreIcon sx={{ fontSize: "1.2rem", color: "#fff" }} />
							}>
							<Typography
								sx={{
									fontSize: "18px",
									fontWeight: "400",
									fontFamily: "Tajawal",
									color: "#fff",
								}}>
								{questionAnswer.QuestionTitle}
							</Typography>
						</AccordionSummary2>

						<AccordionDetails>
							<Typography
								sx={{
									fontSize: "18px",
									fontWeight: "400",
									fontFamily: "Tajawal",
									color: "#200407",
									backgroundColor: "#FFDE06",
									padding: "12px",
								}}>
								{questionAnswer.questionAnswer}
							</Typography>
						</AccordionDetails>
					</Accordion>
				))}
			</AccordionDetails>
		</Accordion>
	));

	return <div className='pb-20 bg-white mb-4'>{commonQuestion}</div>;
}

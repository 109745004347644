import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

// import components
import {
	ArticleBlock,
	FixedMenu,
	Navbar,
	PagesLinks,
	VideoComponent,
} from "../../components";

// Import icons and images
import { ArrowRightOutline, Logo } from "../../assets/icons";

// IMPORT STYLES
import "../../index.css";

const ProgramAdvantages = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title>مميزات البرنامج التدريبي</title>
			</Helmet>

			<div className={`openPages bg-white h-full overflow-y-auto pb-4`}>
				{/*Navbar */}
				<Navbar
					background={"bg-red"}
					pageTitle={
						<img
							onClick={() => navigate("/")}
							className='h-[53px] max-w-[98px] w-auto cursor-pointer'
							src={Logo}
							alt='website logo'
						/>
					}
				/>
				{/*------------------------------------------*/}

				<div className='container'>
					{/* back to home icon */}
					<div className='flex justify-start gap-6 mb-5'>
						<ArrowRightOutline
							className=' cursor-pointer'
							onClick={() => navigate("/business-trip-program-to-china")}
						/>

						<div className='bg-yellow h-[46px] p-1 flex justify-center items-center'>
							<h2
								className={`page_title text-center  font-Tajawal font-bold text-xl text-black `}>
								لماذا اخترنا الصين وجهتنا التجارية؟
							</h2>
						</div>
					</div>

					{/* Video Section 
				
					<div className='max-h[190px] mb-5 '>
						<VideoComponent
							src={"https://placehold.co/398x222.mp4"}
							poster={"https://placehold.co/398x222"}
						/>
					</div>
				
				*/}

					{/*Articles blocks*/}
					<ArticleBlock />
				</div>
			</div>

			{/* Pages Routs  */}
			<div className='sticky bottom-0 left-0 w-full bg-white py-5 flex justify-center items-center'>
				{/*
			<PagesLinks
					title={"آراء المتدربين"}
					arrow_icon={
						<ArrowRightOutline className='rotate-90 cursor-pointer ' />
					}
					route={"/trainees-opinions"}
				/>
			*/}

				<PagesLinks
					title={"باقات الاشتراك"}
					/*arrow_icon={
						<ArrowRightOutline className='rotate-180 cursor-pointer' />
					}*/

					arrow_icon={
						<ArrowRightOutline className='rotate-90 cursor-pointer ' />
					}
					route={"/subscribe-training-program"}
				/>
			</div>

			{/* Fixed Menu */}
			<FixedMenu posBottomValue='bottom-[128px]' />
		</HelmetProvider>
	);
};

export default ProgramAdvantages;

import React, { useEffect, useState } from "react";

// styles
import "../../index.css";
import styles from "./CustomerServiceForm.module.css";
import { RedStarIcon } from "../../assets/icons";

const CustomerServiceForm = () => {
	const [customerService, setCustomerService] = useState({
		fullName: "",
		phonenumber: "",
		email: "",
		message: "",
	});
	// Handle onChange function
	const handleChangeCustomerService = (event) => {
		const { name, value } = event.target;
		setCustomerService((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	const [customerServiceErrors, setCustomerServiceErrors] = useState({
		fullName: "",
		phonenumber: "",
		email: "",
		message: "",
	});

	const resetCustomerServiceErrors = () => {
		setCustomerServiceErrors({
			fullName: "",
			phonenumber: "",
			email: "",
			message: "",
		});
	};
	const resetCustomerServiceInputs = () => {
		setCustomerServiceErrors({
			fullName: "",
			phonenumber: "",
			email: "",
			message: "",
		});
	};

	/** -----------------------------------------------------------------------------------------------------------
	 *  	=> TO HANDLE THE REG_EXPRESS <=
	 *  ------------------------------------------------- */
	const FULL_NAME_REGEX =
		/^[\p{L}\p{M}\p{Zs}.'-]+(\s[\p{L}\p{M}\p{Zs}.'-]+){2,}$/u;
	const PHONE_REGEX = /^(5\d{8})$/;
	const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

	const [validFullName, setValidFullName] = useState(false);
	const [fullNameFocus, setFullNameFocus] = useState(false);

	const [validPhoneNumber, setValidPhoneNumber] = useState(false);
	const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);

	const [validEmail, setValidEmail] = useState(false);
	const [emailFocus, setEmailFocus] = useState(false);

	// TO HANDLE VALIDATION FOR OWNER NAME
	useEffect(() => {
		const fullNameValidation = FULL_NAME_REGEX.test(customerService?.fullName);
		setValidFullName(fullNameValidation);
	}, [customerService?.fullName]);

	// TO HANDLE VALIDATION STORE PHONE NUMBER
	useEffect(() => {
		const phoneNumberValidation = PHONE_REGEX.test(
			customerService?.phonenumber
		);
		setValidPhoneNumber(phoneNumberValidation);
	}, [customerService?.phonenumber]);

	// TO HANDLE VALIDATION FOR EMAIL
	useEffect(() => {
		const emailValidation = EMAIL_REGEX.test(customerService?.email);
		setValidEmail(emailValidation);
	}, [customerService?.email]);

	// To send Data to Server
	const sendCustomerServiceRequest = () => {
		resetCustomerServiceErrors();
		resetCustomerServiceInputs();
	};

	return (
		<form onSubmit={(e) => e.preventDefault()}>
			<div className={`${styles.form_style} bg-white rounded-[10px] p-4 mb-5`}>
				{/* الاسم ثلاثي */}
				<div className={`mb-4`}>
					<h4
						className={`text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline gap-1 `}>
						<span>الاسم ثلاثي</span>
						<span>
							<RedStarIcon />
						</span>
					</h4>
					<input
						type='text'
						name='fullName'
						required
						value={customerService.fullName}
						onChange={handleChangeCustomerService}
						aria-invalid={validPhoneNumber ? "false" : "true"}
						aria-describedby='fullName'
						onFocus={() => setFullNameFocus(true)}
						onBlur={() => setFullNameFocus(true)}
						placeholder='ادخل الإسم'
						className={` w-full h-[30px] ${
							customerServiceErrors.fullName ||
							(fullNameFocus && customerService?.fullName && !validFullName) ||
							(fullNameFocus && customerService.fullName.trim() === "")
								? " border-b-[#fc012cff] "
								: "border-b-[#C6C6C6] "
						} focus:outline-none placeholder:text-[#C6C6C6]  border-b-[1px]  text-[14px] font-normal font-Tajawal p-2 `}
					/>
					{/* Handle errors and validation messages */}
					<span className=' text-[14px] text-[#fc012cff] '>
						{fullNameFocus &&
							customerService.fullName.trim() === "" &&
							"يرجي ادخال الاسم الثلاثي!"}
						{customerServiceErrors.fullName && customerServiceErrors.fullName}
					</span>
					<div
						id='fullName'
						className={
							fullNameFocus && customerService?.fullName && !validFullName
								? "block text-[14px] text-[#fc012cff] "
								: "hidden"
						}>
						يرجي كتابه الاسم بالكامل كما هو في الهوية.
					</div>
				</div>
				{/* رقم الجوال */}
				<div className={`mb-4`}>
					<h4
						className={`text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline gap-1 `}>
						<span>رقم الجوال</span>
						<span>
							<RedStarIcon />
						</span>
					</h4>
					<input
						type='tel'
						name='phonenumber'
						required
						maxLength={9}
						value={customerService.phonenumber}
						onChange={handleChangeCustomerService}
						aria-invalid={validPhoneNumber ? "false" : "true"}
						aria-describedby='phoneNumber'
						onFocus={() => setPhoneNumberFocus(true)}
						onBlur={() => setPhoneNumberFocus(true)}
						placeholder='أدخل رقم الجوال'
						className={` w-full h-[30px] ${
							customerServiceErrors.phonenumber ||
							(phoneNumberFocus &&
								customerService?.phonenumber &&
								!validPhoneNumber) ||
							(phoneNumberFocus && customerService.phonenumber.trim() === "")
								? " border-b-[#fc012cff] "
								: "border-b-[#C6C6C6] "
						} focus:outline-none placeholder:text-[#C6C6C6]  border-b-[1px]  text-[14px] font-normal font-Tajawal p-2 `}
					/>
					{/* Handle errors and validation messages */}
					<span className=' text-[14px] text-[#fc012cff] '>
						{phoneNumberFocus &&
							customerService.phonenumber.trim() === "" &&
							"يرجي ادخال رقم الهاتف!"}
						{customerServiceErrors.phonenumber &&
							customerServiceErrors.phonenumber}
					</span>

					<div
						id='phoneNumber'
						className={
							phoneNumberFocus &&
							customerService?.phonenumber &&
							!validPhoneNumber
								? "block text-[14px] text-[#fc012cff] "
								: "hidden"
						}>
						تأكد ان رقم الجوال يبدأ برقم 5 ولا يقل عن 9 أرقام
					</div>
				</div>
				{/* البريد الإلكتروني */}
				<div className={`mb-4`}>
					<h4
						className={`text-[16px] font-Tajawal font-normal text-[#707070] pb-4 flex justify-start items-baseline gap-1 `}>
						<span>البريد الإلكتروني</span>
						<span>
							<RedStarIcon />
						</span>
					</h4>
					<input
						type='email'
						name='email'
						required
						value={customerService.email}
						onChange={handleChangeCustomerService}
						aria-invalid={validPhoneNumber ? "false" : "true"}
						aria-describedby='email'
						onFocus={() => setEmailFocus(true)}
						onBlur={() => setEmailFocus(true)}
						placeholder='أدخل البريد الإلكرتوني'
						className={` w-full h-[30px] ${
							customerServiceErrors.email ||
							(emailFocus && customerService?.email && !validEmail) ||
							(emailFocus && customerService.email.trim() === "")
								? " border-b-[#fc012cff] "
								: "border-b-[#C6C6C6] "
						} focus:outline-none placeholder:text-[#C6C6C6]  border-b-[1px]  text-[14px] font-normal font-Tajawal p-2 `}
					/>
					{/* Handle errors and validation messages */}
					<span className=' text-[14px] text-[#fc012cff] '>
						{emailFocus &&
							customerService.email.trim() === "" &&
							"يرجي ادخال البريد الالكتروني!"}
						{customerServiceErrors.email && customerServiceErrors.email}
					</span>

					<div
						id='email'
						className={
							emailFocus && customerService?.email && !validEmail
								? "block text-[14px] text-[#fc012cff] "
								: "hidden"
						}>
						يرجي كتابة البريد الالكتروني بشكل صحيح.
					</div>
				</div>
				{/*  نص الرسالة  */}
				<div className={`mb-2`}>
					<textarea
						name='message'
						onChange={handleChangeCustomerService}
						value={customerService.message}
						placeholder='نص الرسالة'
						style={{ resize: "none" }}
						className={`
                   w-full h-20 bg-gray-50 rounded-sm  focus:outline-none placeholder:text-[#C6C6C6]  text-[14px] font-normal font-Tajawal p-2 `}
					/>
					<span className=' text-[14px] text-[#fc012cff] '>
						{customerServiceErrors.message && customerServiceErrors.message}
					</span>
				</div>
			</div>

			<button
				disabled={
					customerService.fullName &&
					customerService.phonenumber &&
					customerService.email &&
					customerService.message
						? false
						: true
				}
				onClick={() => sendCustomerServiceRequest}
				className={`${styles.btn_drop_shadow} cursor-pointer w-full h-[49px]  bg-yellow text-black  rounded-[28px] text-xl font-Tajawal font-medium mb-5`}>
				ارسال
			</button>
		</form>
	);
};

export default CustomerServiceForm;
